import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components';

import { mobile } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import theme from 'utils/theme';

interface EmailFormProps {
  loading: boolean;
  error: string;
  placeholder: string;
  continueBtnTitle: string;
  onSubmit: (email: string) => void;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media ${mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${mobile} {
    max-width: 343px;
    width: 100%;
  }
`;

const Submit = styled(PrimaryButton)`
  margin-top: 1rem;
  width: 420px;
  height: 48px;
  background-color: ${theme.colors};
  font-weight: 400;
  @media ${mobile} {
    max-width: 343px;
    width: 100%;
  }
`;

const InputStyled = styled.input`
  background-color: #fbfbff;
  border: 1px solid #e4e4eb;
  border-radius: 100px;
  outline: none;
  font-weight: 400;
  width: 420px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.dark80};
  height: 48px;
  padding: 0 1.5rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.dark60};
  }

  @media ${mobile} {
    padding: 0 1rem;
    max-width: 343px;
    width: 100%;
  }
`;

const Error = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: red;
  margin: 0.5rem auto;
`;

const EmailForm: FC<EmailFormProps> = ({
  loading,
  error,
  placeholder = 'Please enter your email',
  continueBtnTitle = 'Continue',
  onSubmit,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(email.toLowerCase());
  };

  return (
    <Container onSubmit={handleSubmit} {...props}>
      <ContentContainer>
        <InputStyled
          name="email"
          type="email"
          placeholder={placeholder}
          disabled={loading}
          required
          onChange={handleChange}
        />
        {error ? <Error>{error}</Error> : null}
      </ContentContainer>
      <Submit
        disabled={loading}
        loading={loading}
        type="submit"
        cornerVariant="round"
      >
        {continueBtnTitle}
      </Submit>
    </Container>
  );
};

export default EmailForm;
